import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getPanelImagePath, getInverterImagePath } from '../utils';
import Filters from './Filters';
import { manufacturers } from '../constants';
import PurchaseButton from './PurchaseButton';
import { Pagination } from '@mui/material';
import Stack from '@mui/material/Stack';

const Products = ({ apiUrl, productType }) => {
  const [products, setProducts] = useState([]);
  const [selectedPanels, setSelectedPanels] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [minPower, setMinPower] = useState('');
  const [maxPower, setMaxPower] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('There was an error fetching the products!', error);
      }
    };

    fetchProducts();
  }, [apiUrl]);

  const handlePanelChange = (panel) => {
    setSelectedPanels((prev) =>
      prev.includes(panel) ? prev.filter((p) => p !== panel) : [...prev, panel]
    );
  };

  const handleManufacturerChange = (manufacturer) => {
    setSelectedManufacturers((prev) =>
      prev.includes(manufacturer) ? prev.filter((p) => p !== manufacturer) : [...prev, manufacturer]
    );
  };

  const panelCounts = products.reduce((acc, product) => {
    acc[product.type] = (acc[product.type] || 0) + 1;
    return acc;
  }, {});

  const manufacturerCounts = products.reduce((acc, product) => {
    acc[product.manufacturer] = (acc[product.manufacturer] || 0) + 1;
    return acc;
  }, {});

  const handleMinPowerChange = (value) => {
    setMinPower(value);
  };

  const handleMaxPowerChange = (value) => {
    setMaxPower(value);
  };

  const filteredProducts = products.filter((product) => {
    const panelMatch = selectedPanels.length === 0 || selectedPanels.includes(product.type);
    const manufacturerMatch = selectedManufacturers.length === 0 || selectedManufacturers.includes(product.manufacturer);
    const minPowerMatch = minPower === '' || product.power >= parseInt(minPower);
    const maxPowerMatch = maxPower === '' || product.power <= parseInt(maxPower);

    return panelMatch && manufacturerMatch && minPowerMatch && maxPowerMatch;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className='flex flex-col items-center'>
      <div className='flex w-full'>
        <Filters
          manufacturers={Object.keys(manufacturers)}
          panelCounts={panelCounts}
          manufacturerCounts={manufacturerCounts}
          onPanelChange={handlePanelChange}
          onManufacturerChange={handleManufacturerChange}
          onMinPowerChange={handleMinPowerChange}
          onMaxPowerChange={handleMaxPowerChange}
        />
        <div className="mx-16 w-full">
          <ul>
            {currentProducts.map((product, index) => (
              <li key={index} className="p-4 border-4 border-gray-200 flex m-auto mb-6 rounded-xl shadow-xl">
                <Link to={`/${productType}/${product.id}`}>
                  <img
                    src={productType === 'panel'
                      ? getPanelImagePath(product.manufacturer, product.type)
                      : getInverterImagePath(product.manufacturer, product.phase, product.type, product.power)}
                    alt={product.name}
                    className='w-56 h-72 object-fit mx-10 rounded-3xl my-4'
                  />
                </Link>
                <div className='mr-16'>
                  <Link to={`/${productType}/${product.id}`}>
                    <h3 className="font-bold text-4xl ml-12">{product.name}</h3>
                  </Link>
                  <div className='grid grid-cols-5 gap-4 text-center text-2xl text-blue-600 mt-16'>
                    <span>Moc</span>
                    <span>Producent</span>
                    <span>Dostępność</span>
                    {productType === 'panel' ? (
                      <span>Cena za Wp</span>
                    ) : (
                      <span>Fazy</span>
                    )}
                  </div>
                  <div className='grid grid-cols-5 gap-4 text-center'>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-4 py-1 text-xl'>
                      {productType === 'panel' ? `${product.power} Wp` : `${product.power} kW`}
                    </p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-4 py-1 text-xl'>{product.manufacturer}</p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-4 py-1 text-xl whitespace-nowrap'>{product.availability}</p>
                    {productType === 'panel' ? (
                      <div className='col-span-1 relative m-auto'>
                        <p className='border-2 rounded-lg px-4 py-1 text-xl'>{(product.price_netto / product.power).toFixed(2)} zł/Wp</p>
                        <p className='absolute text-sm text-gray-400' style={{ top: '100%', left: '50%', transform: 'translateX(-50%)' }}>bez VAT</p>
                      </div>
                    ) : (
                      <p className='col-span-1 border-2 rounded-lg m-auto px-4 py-1 text-xl'>{product.phase}</p>
                    )}
                    <div className='col-span-1'>
                      <p className='font-bold text-blue-600 text-5xl whitespace-nowrap'>{product.price} zł</p>
                      <p className='text-sm text-gray-400'>{product.price_netto} zł bez VAT</p>
                    </div>
                    <p className='col-span-1 text-gray-400 mt-12'>Seller: {product.seller}</p>
                    <p className='col-span-3 text-gray-400 mt-12'>Wysyłka: {product.shipping}</p>
                    <a href={product.link} target="_blank" rel="noopener noreferrer">
                      <PurchaseButton link={product.link} />
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Stack spacing={2} className='justify-center mt-10'>
        <Pagination
          count={Math.ceil(filteredProducts.length / productsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color='primary'
          size='large'
          showFirstButton showLastButton
        />
      </Stack>
    </div>
  );
};

export default Products;
