import React from 'react'
import PropTypes from 'prop-types'

const ToggleMoreInfoButton = ({ onClick, isExpanded }) => {
  return (
    <button onClick={onClick} className="text-lg text-center text-blue-600 hover:underline hover:text-gray-400">
      {isExpanded ? 'Mniej informacji' : 'Więcej informacji'}
    </button>
  );
};

ToggleMoreInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default ToggleMoreInfoButton