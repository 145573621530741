import React from 'react';

const Signup = () => {
  return (
    <div className="py-10 flex items-center justify-center">
      <div className="flex w-3/4 max-w-4xl bg-white shadow-lg rounded-2xl overflow-hidden">
        <div className="w-1/2 flex items-center justify-center bg-cover">
          <img src="panda.jpg" alt="Signup Illustration" className="h-full object-cover" />
        </div>
        <div className="w-1/2 px-16 py-8">
          <h2 className="text-3xl font-semibold text-blue-600 mb-8">
            Rejestracja
          </h2>
          <form>
            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block text-gray-700 text-sm font-semibold">Imię</label>
                <input type="text" className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
              </div>
              <div className="w-1/2 pl-2">
                <label className="block text-gray-700 text-sm font-semibold">Nazwisko</label>
                <input type="text" className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">Email</label>
              <input type="email" className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">Hasło</label>
              <input type="password" className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">Powtórz hasło</label>
              <input type="password" className="w-full px-2 py-1 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
            </div>
            <div className="mb-4">
              <input type="checkbox" id="terms" className="mr-2 leading-tight" />
              <label htmlFor="terms" className="text-gray-700 text-sm">Przeczytałem i zgadzam się z Warunkami świadczenia usług i naszą Polityką prywatności</label>
            </div>
            <button className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300">Zarejestruj się</button>
          </form>
          <p className="text-center text-gray-600 mt-4 font-semibold text-sm">Posiadasz już konto? <a href="/signin" className="text-blue-600 hover:underline">Zaloguj się</a></p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
