export const getPanelImagePath = (manufacturer, type) => {
  const panelTypeMap = {
    'Full Black': 'fb',
    'Standard': 'standard',
    'Bifacial': 'bifacial'
  };

  const panelTypeKey = panelTypeMap[type];
  const manufacturerKey = manufacturer.toLowerCase();

  return `/Panels/${manufacturerKey}/${manufacturerKey.replace(/\s+/g, '')}-${panelTypeKey}.jpg`;
};

export const getPanelManufacturerLogoPath = (manufacturer) => {
  const formattedManufacturer = manufacturer.toLowerCase().replace(/\s+/g, '');

  return `/panels_manufacturers/${formattedManufacturer}.jpg`;
};

export const getInverterImagePath = (manufacturer, phase, type = 'Sieciowy', power) => {
  const phaseMap = {
    '1 Fazowy': '1phase',
    '3 Fazowy': '3phase'
  };

  const inverterTypeMap = {
    'Hybrydowy': 'hybrydowy',
    'Sieciowy': 'sieciowy',
    'Mikroinwerter': 'mikroinwerter'
  };

  const manufacturerKey = manufacturer.toLowerCase();
  const phaseKey = phaseMap[phase];
  const typeKey = inverterTypeMap[type] || inverterTypeMap['Sieciowy'];

  let powerKey = '';

  const powerRangeMap = [
    { min: 1, max: 20, label: 'max20kw' },
    { min: 20, max: 69, label: '20-69kw' },
    { min: 20, max: Infinity, label: '20kw+' },
    { min: 25, max: Infinity, label: '25kw+' },
    { min: 30, max: 30, label: '30kw' },
    { min: 50, max: Infinity, label: '50kw+' },
    { min: 70, max: Infinity, label: '70kw+' },
  ];

  const powerRange = powerRangeMap.find((range) => power >= range.min && power <= range.max);

    if (powerRange) {
      powerKey = `-${powerRange.label}`;
    }

  return `/Inverters/${manufacturerKey}/${manufacturerKey}-${phaseKey}${typeKey ? `-${typeKey}` : ''}${powerKey}.jpg`;
}

export const getInverterManufacturerLogoPath = (manufacturer) => {
  const formattedManufacturer = manufacturer.toLowerCase().replace(/\s+/g, '');

  return `/inverters_manufacturers/${formattedManufacturer}.jpg`;
};