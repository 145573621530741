import React from 'react'
import Text from '../components/Text'
import Searchbar from '../components/Searchbar'
import Hero from '../components/Hero'

const Home = () => {
  return (
    <>
      <Text />
      {/* <Searchbar /> */}
      <Hero />
    </>
  )
}

export default Home