import React from 'react'

const PurchaseButton = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className='flex justify-center'>
      <button className='flex justify-center border-black rounded-md px-2 py-2 mt-2 bg-blue-600 hover:bg-blue-500 transition ease-in-out duration-150 text-white whitespace-nowrap'>
        <img src='/cart-button.svg' alt="Cart" className='w-6 h-6 inline-block mr-2' />
        PRZEJDŹ DO ZAKUPU
      </button>
    </a>
  )
}

export default PurchaseButton