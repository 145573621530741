import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Filters = ({
  manufacturers,
  onPanelChange,
  onManufacturerChange,
  panelCounts,
  manufacturerCounts,
  onMinPowerChange,
  onMaxPowerChange }) => {

  const [showPanels, setShowPanels] = useState(true);
  const [showManufacturers, setShowManufacturers] = useState(true);
  const [visibleManufacturersCount, setVisibleManufacturersCount] = useState(5);
  const [showMinMaxPower, setShowMinMaxPower] = useState(true);

  const togglePanels = () => setShowPanels(!showPanels);
  const toggleManufacturers = () => setShowManufacturers(!showManufacturers);
  const toggleMinMaxPower = () => setShowMinMaxPower(!showMinMaxPower);

  const showMoreManufacturers = () => {
    setVisibleManufacturersCount((prevCount) => prevCount + 10);
  }

  return (
    <section>
      <div className="p-4 w-80 border-r-2 sticky top-20">
        <div className="border-b-2 py-4">
          <h2 className="font-semibold mb-2 flex justify-between items-center cursor-pointer text-blue-600" onClick={togglePanels}>
            Rodzaj panelu
            <FontAwesomeIcon icon={showPanels ? faChevronUp : faChevronDown} />
          </h2>
          {showPanels && (
            <div>
              <label className="block">
                <input type="checkbox" className="mr-2" onChange={() => onPanelChange('Standard')} />
                Standard <span className='text-xs text-blue-600'>({panelCounts['Standard'] || 0})</span>
              </label>
              <label className="block">
                <input type="checkbox" className="mr-2" onChange={() => onPanelChange('Full Black')} />
                Full Black <span className='text-xs text-blue-600'>({panelCounts['Full Black'] || 0})</span>
              </label>
              <label className="block">
                <input type="checkbox" className="mr-2" onChange={() => onPanelChange('Bifacial')} />
                Bifacjalne <span className='text-xs text-blue-600'>({panelCounts['Bifacial'] || 0})</span>
              </label>
            </div>
          )}
        </div>

        <div className="py-4 border-b-2">
          <h2 className="font-semibold mb-4 flex justify-between items-center cursor-pointer text-blue-600" onClick={toggleMinMaxPower}>
            Przedział mocy
            <FontAwesomeIcon icon={showMinMaxPower ? faChevronUp : faChevronDown} />
          </h2>
          {showMinMaxPower && (
            <div className='flex items-center justify-center mx-auto gap-4'>
              <label className="block text-center text-sm">
                <input
                  type="number"
                  className="block w-12 h-6 text-black text-center rounded-lg"
                  onChange={(e) => onMinPowerChange(e.target.value)}
                  placeholder="Min"
                />
              </label>
              <div className='w-3 h-0.5 bg-blue-500'></div>
              <label className="block text-center text-sm">
                <input
                  type="number"
                  className="block w-12 h-6 text-black text-center rounded-lg"
                  onChange={(e) => onMaxPowerChange(e.target.value)}
                  placeholder="Max"
                />
              </label>
            </div>
          )}
        </div>

        <div className="py-4 border-b-2">
          <h2 className="font-semibold mb-2 flex justify-between items-center cursor-pointer text-blue-600" onClick={toggleManufacturers}>
            Producent
            <FontAwesomeIcon icon={showManufacturers ? faChevronUp : faChevronDown} />
          </h2>
          {showManufacturers && (
            <div>
              {manufacturers.slice(0, visibleManufacturersCount).map((manufacturer) => (
                <label key={manufacturer} className="block">
                  <input type="checkbox" className='mr-2' onChange={() => onManufacturerChange(manufacturer)} />
                  {manufacturer} <span className='text-xs text-blue-600'>({manufacturerCounts[manufacturer] || 0})</span>
                </label>
              ))}
              {visibleManufacturersCount < manufacturers.length && (
                <button onClick={showMoreManufacturers} className='mt-2 text-blue-500 text-xs hover:underline' >
                  Pokaż więcej
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Filters;
