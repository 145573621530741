import React from 'react'
import MultiRangeSlider from '../components/MultiRangeSlider/MultiRangeSlider'
import Products from '../components/Products'
import Sort from '../components/Sort'

const Inverters = () => {
  return (
    <>
      {/* <MultiRangeSlider /> */}
      <div className='flex justify-end mr-72 mt-10'>
        <Sort />
      </div>
      <div className='flex mt-20'>
        <Products apiUrl="/api/inverters/" productType="inwerter" />
      </div>
    </>
  )
}

export default Inverters
