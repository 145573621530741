import React from 'react'

const Footer = () => {
  return (
    <footer className='text-white bg-[#111] mt-20'>
      <div className='grid grid-cols-1 text-center'>
        <h3 className='text-3xl font-normal mt-4 cursor-default'>
          Volt Value
        </h3>
        <div className='flex justify-center my-8 text-sm'>
          <p className='mx-8 cursor-pointer hover:underline'>Regulamin</p>
          <p className='mx-8 cursor-pointer hover:underline'>FAQ</p>
          <p className='mx-8 cursor-pointer hover:underline'>Polityka prywatności</p>
        </div>
      </div>
      <div className='text-center bg-black text-xs '>
        <p className='p-2'>Copyright &copy;2024 VoltValue. All Rights Reserved.</p>
      </div>
    </footer>
  )
}

export default Footer