import React from 'react'

const Signin = () => {
  return (
    <div className="py-24 flex items-center justify-center">
      <div className="flex w-3/4 max-w-4xl bg-white shadow-lg rounded-2xl overflow-hidden">
        <div className="w-1/2 px-16 py-12">
          <h2 className="text-3xl font-semibold text-blue-600 mb-8">
            Logowanie
          </h2>
          <form>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">Email</label>
              <input type="email" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">Hasło</label>
              <input type="password" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600" />
            </div>
            <button className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300 mt-8">Zarejestruj się</button>
          </form>
          <p className="text-center text-gray-600 mt-4 font-semibold text-sm">Nie posiadasz konta? <a href="/signup" className="text-blue-600 hover:underline">Zarejestruj się</a></p>
        </div>
        <div className="w-1/2 flex items-center justify-center bg-cover">
          <img src="turtle.jpg" alt="Signin Illustration" className="h-full object-cover" />
        </div>
      </div>
    </div>
  );
}

export default Signin