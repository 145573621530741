export const manufacturers = {
  "AE Solar": ["aesolar", "Aesolar","AE Solar"],
  "AIKO": ["Aiko"],
  "Akcome": ["AKCOME"],
  "Astronergy": ["ASTRONERGY", "Astronergy", "Astro"],
  "Axitec": ["AXI"],
  "Bauer": ["bauer", "BAUER"],
  "Beyondsun": ["beyondsun", "BEYONDSUN"],
  "Canadian Solar": ["CanadianSolar", "Canadian"],
  "DAH Solar": ["Dah Solar", "DAH", "dahsolar", "DAHSOLAR", "Dahsolar"],
  "DAS Solar": ["DAS SOLAR", "Das Solar", "Dassolar"],
  "Encor": ["ENCOR", "encor", "CorabEncor"],
  "Hyundai": ["hyundai", "HYUNDAI"],
  "IBC": ["ibc", "Ibc"],
  "JA Solar": ["JA SOLAR", "Ja Solar", "ja solar", "JaSolar", "JASOLAR", "jasolar"],
  "Jetion": ["jetion", "JETION"],
  "Jinko Solar": ["JINKO", "Jinko", "jinko", "JKM"],
  "Jolywood": ["JOLYWOOD"],
  "Kensol": ["KENSOL"],
  "LG": ["NeON", "lg"],
  "Leapton Solar": ["LEAPTON SOLAR", "LEAPTON", "leapton", "Leapton"],
  "Ledvance": ["LEDVANCE", "ledvance"],
  "LONGi Solar": ["LONGI", "Longi", "LONGi", "72HIBD"],
  "Meyer Burger": ["Meyer", "MeyerBurger", "Meyerburger"],
  "Phono Solar": ["PHONO", "PhonoSolar", "Phonosolar"],
  "QCells": ["Q-Cells", "Q-CELLS", "Q Cells", "Q CELLS", "Q PEAK", "Q‑CELLS", "Q.PEAK", "QCELLS"],
  "REC": ["Rec", "TwinPeak"],
  "Risen": ["RISEN", "risen"],
  "Saronic": ["SARONIC"],
  "Selfa": ["SELFA", "selfa"],
  "Sharp": ["SHARP", "sharp"],
  "SoliTek": ["Soli Tek", "solitek", "SOLITEK"],
  "SunLink": ["Sunlink", "SUNLINK"],
  "Sunova": ["sunova", "SUNOVA"],
  "SunPower": ["sunpower", "SUNPOWER", "SUN-POWER", "Sun-Power"],
  "Suntech": ["suntech", "SUNTECH"],
  "Swiss Solar": ["SWISS SOLAR"],
  "Tongwei": ["TW Solar", "tongwei", "TONGWEI"],
  "Trina Solar": ["Trina", "TRINA", "Vertex"],
  "Ulica Solar": ["UlicaSolar", "Ulica Solar"],
  "Viessmann": ["VIESSMANN", "viessmann"],
  "ZnShine": ["znshine", "Znshine"]
}

