import './App.css';
import './components/MultiRangeSlider/MultiRangeSlider.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Panels from './pages/Panels';
import Inverters from './pages/Inverters';
import Home from './pages/Home';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Signup from './pages/Signup';
import Signin from './pages/Signin';
import PanelDetails from './pages/PanelDetails';
import InverterDetails from './pages/InverterDetails';
import { ThemeProvider } from './ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className='flex flex-col min-h-screen'>
          <Navbar />
          <div className='flex-grow'>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/panele" element={<Panels />} />
              <Route path="/panel/:id" element={<PanelDetails />} />
              <Route path="/inwertery" element={<Inverters />} />
              <Route path="/inwerter/:id" element={<InverterDetails />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin" element={<Signin />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </ThemeProvider >
  );
}

export default App;
