import React from 'react'
import { Link } from 'react-router-dom'
import ToggleThemeButton from './ToggleThemeButton'

const Navbar = () => {
  return (
    <nav className='h-20 flex items-center justify-between backdrop-blur-md sticky top-0 z-20 border-b-2'>
      <div className='ml-32 cursor-pointer'>
        <Link to="/">
          <h1 className='text-blue-500 text-xl hover:scale-110 transition duration-200 ease-in-out dark:text-pink-500'>VOLT VALUE</h1>
        </Link>
      </div>
      <ul className='flex text-center text-lg text-blue-600'>
        <li className='mr-32 hover:scale-110 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer'>
          <Link to="/">Home</Link>
        </li>
        <li className='mr-32 hover:scale-110 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer'>
          About
        </li>
        <li className='hover:scale-110 hover:text-blue-500 transition-all duration-200 ease-in-out cursor-pointer'>
          Contact
        </li>
      </ul>
      <div className='flex justify-between items-center'>
        {/* <div className='flex space-x-20'>
          <Link to="/signup">
            <button className='bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition-all duration-200 ease-in-out'>
              Sign Up
            </button>
          </Link>
          <Link to="/signin">
            <button className='bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-500 transition-all duration-200 ease-in-out'>
              Sign In
            </button>
          </Link>
        </div>
        <div className='mr-10 ml-20'>
          <ToggleThemeButton />
        </div> */}
      </div>
    </nav>
  )
}

export default Navbar