import React from 'react'

const Text = () => {
  return (
    <div className="flex items-center justify-center mt-12 p-4 ml-10">
      <div className="w-max">
        <h1 className="animate-typing overflow-hidden whitespace-nowrap border-r-4 border-r-white pr-12 text-2xl text-blue-600 font-bold">
          Oszczędzaj na energii - Porównaj ceny sprzętu OZE w najlepszej cenie!
        </h1>
      </div>
    </div>
  )
}

export default Text