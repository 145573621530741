import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getInverterImagePath } from '../utils';
import PurchaseButton from '../components/PurchaseButton';
import { getInverterManufacturerLogoPath } from '../utils';

const InverterDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`/api/inverters/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error('There was an error fetching the product!', error);
      }
    };

    const fetchSimilarProducts = async () => {
      try {
        const response = await fetch(`/api/similar_products_inverters/${id}`);

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const data = await response.json();
        setSimilarProducts(data);
      } catch (error) {
        console.error('There was an error fetching the similar products!', error);
      }
    };

    fetchProduct();
    fetchSimilarProducts();
  }, [id]);

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="mx-40 my-28">
      <div className='flex'>
        <div className='relative flex flex-col items-center'>
          <img
            src={getInverterManufacturerLogoPath(product.manufacturer)}
            alt={`${product.manufacturer} logo`}
            className='object-contain absolute -top-28 scale-50 border-4 drop-shadow-lg'
          />
          <img
            src={getInverterImagePath(product.manufacturer, product.phase, product.type, product.power)}
            alt={product.name}
            className='w-[500px] h-[500px] min-h-[500px] object-fit rounded-3xl border drop-shadow-lg mt-4'
          />
        </div>
        <div className="w-full ml-20 -mt-12 whitespace-nowrap">
          <h1 className="font-semibold text-4xl border-b-2 border-gray-200 pb-6">{product.name}</h1>
          <div className="flex mt-6 border-b-2 border-gray-200 pb-6">
            <div className="grid grid-cols-2 grid-rows-4 gap-x-12 text-2xl">
              <div className='flex gap-x-6'>
                <span className='text-blue-600'>Producent</span>
                <p className='font-light'>{product.manufacturer}</p>
              </div>
              <div className="col-start-1 row-start-2 flex gap-x-6">
                <span className='text-blue-600'>Fazy</span>
                <p className='font-light'>{product.phase}</p>
              </div>
              <div className="col-start-1 row-start-3 flex gap-x-6">
                <span className='text-blue-600'>Gwarancja</span>
                <p className='font-light'>{product.warranty}</p>
              </div>
              <div className="col-start-1 row-start-4 flex gap-x-6">
                <span className='text-blue-600'>Sprzedawca</span>
                <p className='font-light'>{product.seller}</p>
              </div>
              <div className="col-start-2 row-start-1 flex gap-x-6">
                <span className='text-blue-600'>Rodzaj</span>
                <p className='font-light'>{product.type}</p>
              </div>
              <div className="col-start-2 row-start-2 flex gap-x-6">
                <span className='text-blue-600'>Dostępność</span>
                <p className='font-light'>{product.availability}</p>
              </div>
              <div className="col-start-2 row-start-3 flex gap-x-6">
                <span className='text-blue-600'>Liczba MPPT</span>
                <p className='font-light'>{product.mppt}</p>
              </div>
              <div className='flex gap-x-6'>
                <span className='text-blue-600'>Moc</span>
                <p className='font-light'>{product.power} kW</p>
              </div>
            </div>
            <div className="ml-32 relative text-center">
              <p className="text-lg text-blue-600">od <span className="font-bold text-5xl text-blue-600">{product.price} zł</span></p>
              <p className="text-sm text-gray-400 mb-16">268,94 zł bez VAT</p>
              <p className="text-sm text-gray-400">Wysyłka od</p>
              <PurchaseButton link={product.link} />
              <p className="text-sm text-gray-400 mt-1">Wysyłka w {product.shipping}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-12 pt-4 mx-44'>
        <h2 className='text-center text-lg mb-4'> Inne oferty tego produktu:</h2>
        <div className="">
          <ul>
            {similarProducts.map((similarProduct, index) => (
              <li key={index} className="p-4 border-2 border-gray-200 flex m-auto mb-6 rounded-xl shadow-xl">
                <Link to={`/product/${similarProduct.id}`}>
                  <img
                    src={getInverterImagePath(product.manufacturer, product.phase, product.type, product.power)}
                    alt={similarProduct.name}
                    className='w-32 h-40 object-fit mx-4 rounded-lg my-4'
                  />
                </Link>
                <div className='mr-4'>
                  <Link to={`/product/${similarProduct.id}`}>
                    <h3 className="font-bold text-xl ml-4">{similarProduct.name}</h3>
                  </Link>
                  <div className='grid grid-cols-5 gap-2 text-center text-lg text-blue-600 mt-4'>
                    <span>Moc</span>
                    <span>Producent</span>
                    <span>Dostępność</span>
                    <span>Cena za Wp</span>
                  </div>
                  <div className='grid grid-cols-5 gap-2 text-center'>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base'>{similarProduct.power} Wp</p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base'>{similarProduct.manufacturer}</p>
                    <p className='col-span-1 border-2 rounded-lg m-auto px-2 py-1 text-base whitespace-nowrap'>{similarProduct.availability}</p>
                    <div className='col-span-1 relative m-auto'>
                      <p className='border-2 rounded-lg px-2 py-1 text-base'>{(similarProduct.price_netto / similarProduct.power).toFixed(2)} zł/Wp</p>
                      <p className='absolute text-xs text-gray-400' style={{ top: '100%', left: '50%', transform: 'translateX(-50%)' }}>bez VAT</p>
                    </div>
                    <div className='col-span-1'>
                      <p className='font-bold text-blue-600 text-3xl whitespace-nowrap'>{similarProduct.price} zł</p>
                      <p className='text-xs text-gray-400'>{similarProduct.price_netto} zł bez VAT</p>
                    </div>
                    <p className='col-span-1 text-gray-400 mt-4'>Seller: {similarProduct.seller}</p>
                    <p className='col-span-3 text-gray-400 mt-4'>Wysyłka: {similarProduct.shipping}</p>
                    <a href={similarProduct.link} target="_blank" rel="noopener noreferrer">
                      <PurchaseButton link={similarProduct.link} />
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

  );
}

export default InverterDetails
